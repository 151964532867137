<template>
  <!-- HORIZONTAL LAYOUT -->
  <vx-card title="Create Product Festives">
    <div class="vx-row mb-6" style="width: 50%">
      <vs-button
        class="ml-4 mt-2"
        color="danger"
        icon-pack="feather"
        icon="icon-arrow-left"
        @click="handleBack()"
        >Back</vs-button
      >
    </div>
    <form class="vx-row">
      <div class="vx-col sm:w-1/2 w-full mb-base">
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/4 w-full">
            <span>TOP Promo Code </span>
          </div>
          <div class="vx-col sm:w-2/5 w-full">
            <vs-input class="w-full" v-model="code" disabled />
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/4 w-full">
            <span>Description </span>
          </div>
          <div class="vx-col sm:w-2/5 w-full">
            <vs-input
              type="text"
              class="w-full"
              v-validate="'required'"
              name="description"
              v-model="description"
            />

            <span
              class="text-danger text-sm"
              v-show="errors.has('description')"
              >{{ errors.first("description") }}</span
            >
          </div>
        </div>

        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/4 w-full">
            <span>SO Type</span>
          </div>
          <div class="vx-col sm:w-2/5 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedSoType"
              :options="optionSoType"
              multiple
              :filterable="true"
              v-validate="'required'"
              :allow-empty="true"
              :group-select="true"
              :max-height="100"
              :internal-search="true"
              placeholder="Type to search"
              :searchable="true"
              track-by="ID"
              label="Code"
              name="SoType"
            />
            <span class="text-danger text-sm" v-show="errors.has('SoType')">{{
              errors.first("SoType")
            }}</span>
          </div>
        </div>

        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/4 w-full">
            <span>TOP Model</span>
          </div>
          <div class="vx-col sm:w-3/4 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedTopModel"
              :options="OptionTopModel"
              :multiple="false"
              v-validate="'required'"
              :filterable="true"
              :allow-empty="true"
              :group-select="true"
              :max-height="100"
              :limit="3"
              :internal-search="false"
              placeholder="Type to search"
              :searchable="true"
              track-by="value"
              label="name"
              name="Top"
            />
            <span class="text-danger text-sm" v-show="errors.has('Top')">{{
              errors.first("Top")
            }}</span>
          </div>
        </div>

        <div v-if="selectedTopModel != null">
          <div class="vx-row mb-6">
            <div
              class="vx-col sm:w-1/4 w-full"
              v-if="selectedTopModel.value === 'TOP'"
            >
              <span>TOP Value</span>
            </div>
            <div
              class="vx-col sm:w-1/4 w-full"
              v-if="selectedTopModel.value === 'FDD'"
            >
              <span>FDD Value</span>
            </div>
            <div
              class="vx-col sm:w-3/4 w-full"
              v-if="selectedTopModel.value === 'TOP'"
            >
              <multiselect
                class="selectExample"
                v-model="selectedTOP"
                :options="optionTOP"
                :multiple="false"
                v-validate="'required'"
                :filterable="true"
                :allow-empty="true"
                :group-select="true"
                :max-height="100"
                :limit="3"
                :internal-search="false"
                placeholder="Type to search"
                :searchable="true"
                track-by="ID"
                label="name"
                name="TOP"
                @search-change="handlerSearchChangeTOP"
              />
              <span class="text-danger text-sm" v-show="errors.has('TOP')">{{
                errors.first("TOP")
              }}</span>
            </div>
            <div
              v-if="selectedTopModel.value === 'FDD'"
              class="vx-col sm:w-3/4 w-full"
            >
              <vs-input
                type="date"
                class="w-full"
                v-validate="'required'"
                name="Date"
                v-model="date"
              />
              <span class="text-danger text-sm" v-show="errors.has('Date')">{{
                errors.first("Date")
              }}</span>
            </div>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/4 w-full">
            <span>Supplier</span>
          </div>
          <div class="vx-col sm:w-3/4 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedSupplier"
              :options="optionSupplier"
              :multiple="true"
              :filterable="true"
              :internal-search="false"
              placeholder="Type to search"
              :searchable="true"
              track-by="ID"
              @search-change="handleSearchSupplier"
              :custom-label="customLableSupplier"
              @select="SupplierInput"
              name="Supplier"
              v-validate="'required'"
            />
            <span class="text-danger text-sm" v-show="errors.has('Supplier')">{{
              errors.first("Supplier")
            }}</span>
          </div>
        </div>

        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/4 w-full">
            <span>Valid From</span>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <vs-input
              type="date"
              class="w-full"
              v-validate="'required'"
              name="ValidFrom"
              v-model="validFrom"
              :min="tommorow"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('ValidFrom')"
              >{{ errors.first("ValidFrom") }}</span
            >
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <span>Valid To</span>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <vs-input
              type="date"
              class="w-full"
              v-validate="'required'"
              name="ValidTo"
              v-model="validTo"
              :min="validFrom"
            />
            <span class="text-danger text-sm" v-show="errors.has('ValidTo')">{{
              errors.first("ValidTo")
            }}</span>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/4 w-full">
            <span>Create By</span>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <vs-input type="input" class="w-full" v-model="createBy" disabled />
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <span>Date Create</span>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <vs-input
              type="input"
              class="w-full"
              v-model="dateCreate"
              disabled
            />
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/4 w-full">
            <span>Approved By</span>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <vs-input
              type="input"
              class="w-full"
              v-model="approvedBy"
              disabled
            />
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <span>Date Approved</span>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <vs-input type="input" class="w-full" disabled />
          </div>
        </div>

        <div class="vx-row mb-6" style="width: 70%">
          <div class="vx-col sm:w-1/3 mr-5 w-full">
            <span>Status</span>
          </div>
          <div class="vx-col sm:w-2/5 w-full">
            <vs-switch width="20px" color="success" v-model="status">
              <span slot="on">Active</span>
              <span slot="off">Inactive</span>
            </vs-switch>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/4 w-full">
            <span>Notes</span>
          </div>
          <div class="vx-col sm:w-3/4 w-full">
            <vs-textarea
              v-model="notes"
              class="w-full"
              rows="3"
              name="notes"
            ></vs-textarea>
          </div>
        </div>

        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/4 w-full">
            <label
              >Attachment
              <small style="color: red"
                >(only: jpg, jpeg, pdf, doc, docx, png, xlsx, xls)</small
              ></label
            >
          </div>
          <div class="vx-col sm:w-3/4 w-full">
            <div class="vx-col w-1/2">
              <!-- only accept "jpg", "jpeg", "pdf", "doc", "docx", "png" , "xlsx", "xls"-->
              <input
                id="fileInput"
                name="file"
                class="w-full inputx"
                type="file"
                ref="file"
                multiple="multiple"
                accept=".jpg, .jpeg, .png, .pdf, .doc, .docx, .xlsx, .xls"
              />
            </div>
          </div>
        </div>
        <div
          class="vx-row mb-3 mt-6 w-4/4"
          style="width: 100%; margin-left: 0%"
        >
          <div class="vx-col sm:w-1/4 w-full">
            <span></span>
          </div>
          <div class="vx-col sm:w-3/4 w-full">
            <div class="vx-col sm:w-4/5 w-full">
              <vs-button class="mr-3 mb-2" @click="handleAttachment"
                >Add Attachment</vs-button
              >
            </div>
          </div>
        </div>
        <!-- display: block -->
        <vs-divider style="width: 60%; margin-left: 30%">
          List Attachment
        </vs-divider>
        <div class="vx-row mb-3 mt-6" style="width: 60%; margin-left: 30%">
          <table class="vs-table vs-table--tbody-table">
            <template>
              <tr
                class="tr-values vs-table--tr tr-table-state-null selected"
                v-bind:key="tr.NameFile"
                v-for="(tr, i) in fileAttachment"
              >
                <td class="td vs-table--td">{{ tr.NameFile }}</td>
                <td class="td vs-table--td">
                  <vx-tooltip text="Show" v-if="tr.PathFile != ''">
                    <vs-button
                      type="line"
                      icon-pack="feather"
                      icon="icon-eye"
                      @click.stop="handleShowAttachment(tr)"
                    />
                  </vx-tooltip>
                </td>

                <td class="td vs-table--td">
                  <template>
                    <vx-tooltip text="Delete">
                      <vs-button
                        type="line"
                        icon-pack="feather"
                        icon="icon-trash"
                        @click.stop="handleDeleteAttachment(i)"
                      />
                    </vx-tooltip>
                  </template>
                </td>
              </tr>
            </template>
          </table>
        </div>

        <div class="vx-row">
          <div class="vx-col sm:w-6/12 w-full" v-if="update">
            <vs-button class="mr-3 mb-2" v-on:click="SubmitForm()"
              >Submit</vs-button
            >
          </div>
        </div>
      </div>
      <div class="vx-col sm:w-1/2 w-full mb-base">
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Territory</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedTerritory"
              :options="optionTerritory"
              multiple
              :filterable="true"
              :allow-empty="true"
              v-validate="'required'"
              :group-select="true"
              :max-height="100"
              :limit="3"
              :internal-search="true"
              placeholder="Type to search"
              :searchable="true"
              track-by="ID"
              @select="getCustomer()"
              :custom-label="customLableTerritory"
              name="Territory"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('Territory')"
              >{{ errors.first("Territory") }}</span
            >
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span> Distribution Channel </span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedDistributionChannel"
              :options="optionDistributionChannel"
              multiple
              :filterable="true"
              v-validate="'required'"
              :allow-empty="true"
              :group-select="true"
              :max-height="100"
              :limit="3"
              :internal-search="true"
              placeholder="Type to search"
              :searchable="true"
              track-by="ID"
              @select="getCustomer()"
              :custom-label="customLableDistributionChannel"
              name="DistributionChannel"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('DistributionChannel')"
              >{{ errors.first("DistributionChannel") }}</span
            >
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Customer Group 1 </span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedCustomerGroup1"
              :options="optionCustomerGroup1"
              v-validate="'required'"
              multiple
              :filterable="true"
              :allow-empty="true"
              :group-select="true"
              :max-height="100"
              :limit="3"
              :internal-search="true"
              placeholder="Type to search"
              :searchable="true"
              track-by="ID"
              @select="getCustomer()"
              :custom-label="customLableCustomerGroup1"
              name="CustomerGroup1"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('CustomerGroup1')"
              >{{ errors.first("CustomerGroup1") }}</span
            >
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Customer Group 2</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedCustomerGroup2"
              :options="optionCustomerGroup2"
              multiple
              :filterable="true"
              :allow-empty="true"
              :group-select="true"
              :max-height="100"
              v-validate="'required'"
              :limit="3"
              :internal-search="true"
              placeholder="Type to search"
              :searchable="true"
              track-by="ID"
              @select="getCustomer()"
              :custom-label="customLableCustomerGroup1"
              name="CustomerGroup2"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('CustomerGroup2')"
              >{{ errors.first("CustomerGroup2") }}</span
            >
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Customer Group 3</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedCustomerGroup3"
              :options="optionCustomerGroup3"
              multiple
              :filterable="true"
              :allow-empty="true"
              :group-select="true"
              :max-height="100"
              :limit="3"
              v-validate="'required'"
              :internal-search="true"
              placeholder="Type to search"
              :searchable="true"
              track-by="ID"
              @select="getCustomer()"
              :custom-label="customLableCustomerGroup1"
              name="CustomerGroup3"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('CustomerGroup3')"
              >{{ errors.first("CustomerGroup3") }}</span
            >
          </div>
        </div>

        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Customer</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedCustomer"
              :options="optionCustomer"
              multiple
              v-validate="'required'"
              :filterable="true"
              :allow-empty="true"
              :group-select="true"
              :max-height="100"
              :limit="3"
              :internal-search="false"
              placeholder="Type to search"
              :searchable="true"
              track-by="ID"
              @search-change="getOptionCustomer"
              :custom-label="customLableCustomer"
              name="Customer"
            />
            <span class="text-danger text-sm" v-show="errors.has('Customer')">{{
              errors.first("Customer")
            }}</span>
          </div>
          <!--          <div class="vx-col sm:w-1/6 w-full text-right">-->
          <!--            <vs-button-->
          <!--              size="medium"-->
          <!--              class="mr-2 mb-1 bg-success"-->
          <!--              icon-pack="feather"-->
          <!--              icon="icon-search"-->
          <!--              @click="handleFindCustomer()"-->
          <!--              >refresh</vs-button-->
          <!--            >-->
          <!--          </div>-->
        </div>
      </div>
    </form>
    <hr />
    <br />
    <div class="vx-col sm:w-1/2 w-full mb-base">
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/4 w-full">
          <span>Source</span>
        </div>
        <div class="vx-col sm:w-3/4 w-full">
          <multiselect
            class="selectExample"
            v-model="selectSource"
            :options="OptionSource"
            :multiple="false"
            :filterable="true"
            :allow-empty="false"
            :group-select="true"
            :max-height="100"
            :limit="3"
            :internal-search="false"
            placeholder="Type to search"
            :searchable="true"
            track-by="value"
            label="name"
            name="SourceV1"
            @select="handleSelectSource"
            v-validate="'required'"
          />
          <span class="text-danger text-sm" v-show="errors.has('SourceV1')"
            >Source is required</span
          >
        </div>
      </div>
    </div>
    <hr />
    <div v-if="selectSource != null">
      <div class="vx-row" v-if="selectSource.value == 'SKU'">
        <div class="vx-col sm:w-1/1 w-full mb-base m-10">
          <table width="100%" class="vs-table vs-table--tbody-table">
            <thead class="vs-table--thead">
              <tr>
                <th width="30%">SKU Code</th>
                <th width="25%">SKU Name</th>
                <th width="5%">Default</th>
                <th width="25%" v-if="isAnyDefault">HU</th>
                <th width="30%" v-if="isAnyDefault">Min. Qty [SKU]</th>
                <th width="5%"></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(child, index) in itemLines"
                :key="index"
                style="padding-bottom: 5px"
              >
                <td class="td vs-table--td" style="padding: 5px">
                  <multiselect
                    class="selectExample"
                    v-model="itemLines[index].selected_items"
                    :options="optionItem"
                    :multiple="false"
                    :allow-empty="false"
                    :group-select="false"
                    :max-height="160"
                    :limit="4"
                    placeholder=" Type to search"
                    track-by="item_id"
                    :custom-label="itemCustomLabels"
                    @search-change="onSearchItems($event, true)"
                    name="item"
                  />
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('item')"
                    >{{ errors.first("item") }}</span
                  >
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                  <span>
                    {{
                      itemLines[index].selected_items
                        ? itemLines[index].selected_items.item_name
                        : ""
                    }}
                  </span>
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                  <vs-checkbox
                    color="success"
                    v-model="itemLines[index].isDefault"
                    :disabled="itemLines[index].isAll"
                  />
                </td>
                <td
                  class="td vs-table--td"
                  style="padding-right: 10px"
                  v-if="child.isDefault"
                >
                  <multiselect
                    class="selectExample"
                    v-model="itemLines[index].selected_item_unit"
                    :options="
                      itemLines[index].selected_items
                        ? itemLines[index].selected_items.hu_item_response
                        : allHUOptions
                    "
                    :multiple="false"
                    :allow-empty="false"
                    :group-select="false"
                    :max-height="160"
                    :limit="10"
                    placeholder=" Type to search"
                    track-by="unit_id"
                    label="unit_name"
                    name="itemUnit"
                  />
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('itemUnit')"
                    >{{ errors.first("itemUnit") }}</span
                  >
                </td>
                <td
                  class="td vs-table--td"
                  style="padding-right: 10px"
                  v-if="child.isDefault"
                >
                  <vs-input
                    class="w-full"
                    type="number"
                    v-model="itemLines[index].qty"
                    :min="1"
                  />
                </td>

                <td
                  width="75px"
                  class="td vs-table--td"
                  style="text-align: center"
                >
                  <div class="vx-input-group flex">
                    <vs-button
                      v-if="itemLines.length > 1"
                      @click.stop="removeRow(index)"
                      size="small"
                      color="danger"
                      icon-pack="feather"
                      icon="icon-trash"
                      style="margin-right: 5px"
                      title="Remove Row"
                    />

                    <vs-button
                      v-if="index == itemLines.length - 1"
                      @click.stop="addRow(index)"
                      size="small"
                      color="success"
                      icon-pack="feather"
                      icon="icon-plus"
                      title="Add Row"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="vx-row" v-if="selectSource.value == 'SKUCa'">
        <div class="vx-col sm:w-1/1 w-1/2 mb-base m-10">
          <table width="100%" class="vs-table vs-table--tbody-table">
            <thead class="vs-table--thead">
              <tr>
                <th width="30%">Item Category</th>
                <th width="30%">Name</th>
                <th width="5%"></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(child, index) in itemCategoryLines"
                :key="index"
                style="padding-bottom: 5px"
              >
                <td class="td vs-table--td" style="padding: 5px">
                  <multiselect
                    class="selectExample"
                    v-model="itemCategoryLines[index].selected_items"
                    :options="optionItemCategory"
                    :multiple="false"
                    :allow-empty="false"
                    :group-select="false"
                    :max-height="160"
                    :limit="4"
                    placeholder=" Type to search"
                    track-by="ID"
                    :custom-label="itemCustomLabel"
                    @search-change="onSearchItemCategory($event, true)"
                    name="item"
                  />
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('item')"
                    >{{ errors.first("item") }}</span
                  >
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                  <span>
                    {{
                      itemCategoryLines[index].selected_items
                        ? itemCategoryLines[index].selected_items.name
                        : ""
                    }}
                  </span>
                </td>

                <td
                  width="75px"
                  class="td vs-table--td"
                  style="text-align: center"
                >
                  <div class="vx-input-group flex">
                    <vs-button
                      v-if="itemCategoryLines.length > 1"
                      @click.stop="removeRowItemCategory(index)"
                      size="small"
                      color="danger"
                      icon-pack="feather"
                      icon="icon-trash"
                      style="margin-right: 5px"
                      title="Remove Row"
                    />

                    <vs-button
                      v-if="index == itemCategoryLines.length - 1"
                      @click.stop="addRowItemCategory(index)"
                      size="small"
                      color="success"
                      icon-pack="feather"
                      icon="icon-plus"
                      title="Add Row"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </vx-card>
</template>

<script>
import { is } from "core-js/core/object";
import moment from "moment";
export default {
  props: {
    title: {
      type: String,
    },
    action: {
      type: String,
    },
  },
  components: {},
  mounted() {
    // console.log("mounted - form : ", this.territoryCode, this.territoryId, this.selectedReservation, this.optionTerritory);
    this.update = true;
    this.getTerritory();
    // this.getCustomer();
    this.getCustomerCategory();
    this.getCustomerGroup1();
    this.getCustomerGroup2();
    this.getCustomerGroup3();
    this.getPaymenTemp();
    this.getSoType();
    this.getSupplier();
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
    isAnyDefault() {
      return this.itemLines.some((item) => item.isDefault);
    },
  },
  watch: {
    itemLines: {
      handler(newVal) {
        if (this.itemLines.length < 2) {
          return;
        }
        let cek = this.itemLines.some((el) => {
          if (el.selected_items.item_id == 0) {
            return true;
          }
          return false;
        });
        if (cek) {
          this.itemLines = [
            {
              selected_items: this.optionItem[0],
              option_item_unit: [],
              selected_item_unit: null,
              qty: 0,
              isAll: true,
            },
          ];
        }
      },
      deep: true,
    },
    itemCategoryLines: {
      handler(newVal) {
        if (this.itemCategoryLines.length < 2) {
          return;
        }
        let cek = this.itemCategoryLines.some((el) => {
          if (el.selected_items.ID == 0) {
            return true;
          }
          return false;
        });
        if (cek) {
          this.itemCategoryLines = [
            {
              selected_items: this.optionItemCategory[0],
            },
          ];
        }
      },
      deep: true,
    },

    selectSource: {
      handler(newVal) {
        if (newVal && newVal.value === "SKU") {
          this.itemLines.forEach((item) => {
            item.isDefault = false;
          });
          // this.getOptionItemAvailable();
        } else if (newVal && newVal.value === "SKUCa") {
          // this.getOptionItemCategory();
        }
      },
      deep: true,
    },
    itemsQuantity: function () {
      this.quantityChecked = false;
    },
    validTo: function () {
      if (this.validTo < this.validFrom) {
        this.validTo = this.validFrom;
      }
    },
    validFrom: function () {
      if (this.validTo < this.validFrom) {
        this.validTo = this.validFrom;
      }
    },
    selectedTerritory: function () {
      if (this.selectedTerritory.length > 1) {
        // Check if the array contains the ID 0
        const containsZero = this.selectedTerritory.some(
          (item) => item.ID === 0
        );

        if (containsZero) {
          // If the first element has ID 0, remove it and keep the rest
          if (this.selectedTerritory[0].ID === 0) {
            this.selectedTerritory = this.selectedTerritory.filter(
              (item) => item.ID !== 0
            );
          } else {
            // Otherwise, set the array to only contain the ID 0
            this.selectedTerritory = [{ code: "ALL", name: "", ID: 0 }];
          }
        }
      }
    },
    selectedSoType: function () {
      if (this.selectedSoType.length > 1) {
        // Check if the array contains the ID 0
        const containsZero = this.selectedSoType.some((item) => item.ID === 0);

        if (containsZero) {
          // If the first element has ID 0, remove it and keep the rest
          if (this.selectedSoType[0].ID === 0) {
            this.selectedSoType = this.selectedSoType.filter(
              (item) => item.ID !== 0
            );
          } else {
            // Otherwise, set the array to only contain the ID 0
            this.selectedSoType = [{ Code: "ALL", Name: "", ID: 0 }];
          }
        }
      }
    },
    selectedDistributionChannel: function () {
      if (this.selectedDistributionChannel.length > 1) {
        // Check if the array contains the ID 0
        const containsZero = this.selectedDistributionChannel.some(
          (item) => item.ID === 0
        );

        if (containsZero) {
          // If the first element has ID 0, remove it and keep the rest
          if (this.selectedDistributionChannel[0].ID === 0) {
            this.selectedDistributionChannel =
              this.selectedDistributionChannel.filter((item) => item.ID !== 0);
          } else {
            // Otherwise, set the array to only contain the ID 0
            this.selectedDistributionChannel = [
              { name: "ALL", classification: "", ID: 0 },
            ];
          }
        }
      }
    },
    selectedCustomerGroup1: function () {
      if (this.selectedCustomerGroup1.length > 1) {
        // Check if the array contains the ID 0
        const containsZero = this.selectedCustomerGroup1.some(
          (item) => item.ID === 0
        );

        if (containsZero) {
          // If the first element has ID 0, remove it and keep the rest
          if (this.selectedCustomerGroup1[0].ID === 0) {
            this.selectedCustomerGroup1 = this.selectedCustomerGroup1.filter(
              (item) => item.ID !== 0
            );
          } else {
            // Otherwise, set the array to only contain the ID 0
            this.selectedCustomerGroup1 = [{ code: "ALL", name: "", ID: 0 }];
          }
        }
      }
    },
    selectedCustomerGroup2: function () {
      if (this.selectedCustomerGroup2.length > 1) {
        // Check if the array contains the ID 0
        const containsZero = this.selectedCustomerGroup2.some(
          (item) => item.ID === 0
        );

        if (containsZero) {
          // If the first element has ID 0, remove it and keep the rest
          if (this.selectedCustomerGroup2[0].ID === 0) {
            this.selectedCustomerGroup2 = this.selectedCustomerGroup2.filter(
              (item) => item.ID !== 0
            );
          } else {
            // Otherwise, set the array to only contain the ID 0
            this.selectedCustomerGroup2 = [{ code: "ALL", name: "", ID: 0 }];
          }
        }
      }
    },
    selectedCustomerGroup3: function () {
      if (this.selectedCustomerGroup3.length > 1) {
        // Check if the array contains the ID 0
        const containsZero = this.selectedCustomerGroup3.some(
          (item) => item.ID === 0
        );

        if (containsZero) {
          // If the first element has ID 0, remove it and keep the rest
          if (this.selectedCustomerGroup3[0].ID === 0) {
            this.selectedCustomerGroup3 = this.selectedCustomerGroup3.filter(
              (item) => item.ID !== 0
            );
          } else {
            // Otherwise, set the array to only contain the ID 0
            this.selectedCustomerGroup3 = [{ code: "ALL", name: "", ID: 0 }];
          }
        }
      }
    },
    selectedCustomer: function () {
      if (this.selectedCustomer.length > 1) {
        for (let index = 0; index < this.selectedCustomer.length; index++) {
          if (this.selectedCustomer[index].ID == 0) {
            this.selectedCustomer.splice(index, 1);
          }
        }
      }
    },
    selectedTopModel: function () {
      if (this.selectedTopModel != null) {
        if (this.selectedTopModel.value == "FDD") {
          this.selectedTOP = null;
        } else {
          this.date = null;
        }
      }
    },
    selectedSupplier: function () {
      if (this.selectedSupplier.length > 1) {
        // Check if the array contains the ID 0
        const containsZero = this.selectedSupplier.some(
          (item) => item.ID === 0
        );

        if (containsZero) {
          // If the first element has ID 0, remove it and keep the rest
          if (this.selectedSupplier[0].ID === 0) {
            this.selectedSupplier = this.selectedSupplier.filter(
              (item) => item.ID !== 0
            );
          } else {
            // Otherwise, set the array to only contain the ID 0
            this.selectedSupplier = [{ code: "ALL", name: "", ID: 0 }];
          }
        }
      }
      //append selected supplier id to supplierID
      this.supplierID = this.selectedSupplier.map((item) => item.ID);
      this.getOptionItemAvailable();
      this.getOptionItemCategory();
    },
  },
  data: () => ({
    update: false,
    quantityChecked: false,
    external: false,

    optionItemAvailable: [{}],

    optionCustomerShow: false,

    limits: [10, 25, 50, 100, "All"],
    limitShow: 10,
    drawData: 0,
    isActive: 1,
    recordsTotal: 0,
    maxPage: 2,
    dataEnd: 0,
    disableTerritory: false,

    optionItem: [],
    itemLines: [
      {
        selected_items: null,
        option_item_unit: [],
        selected_item_unit: {
          item_unit_id: 0,
          unit_id: 0,
          unit_name: "ALL",
        },

        qty: 0,
        isDefault: false,
        isAll: true,
      },
    ],
    supplierID: [],
    allHUOptions: [],
    optionTOP: [],
    selectedTOP: null,
    optionSupplier: [],
    selectedSupplier: [],
    optionTerritory: [],
    selectedTerritory: [],
    optionDistributionChannel: [],
    selectedDistributionChannel: [],
    optionCustomerGroup1: [],
    selectedCustomerGroup1: [],
    optionCustomerGroup2: [],
    selectedCustomerGroup2: [],
    optionCustomerGroup3: [],
    selectedCustomerGroup3: [],
    optionCustomer: [],
    selectedCustomer: [],
    optionSoType: [],
    selectedSoType: [],
    optionItemCategory: [],
    itemCategoryLines: [
      {
        selected_items: null,
      },
    ],
    start: 1,
    end: 0,
    length: 10,
    page: 1,
    search: "",
    order: "id",
    sort: "desc",
    total: 0,
    totalPage: 0,
    totalSearch: 0,
    validFrom: "",
    validTo: "",
    date: "",
    day: 0,
    description: "",
    cartProducts: [],
    itemUnitID: [],
    selectedTopModel: null,
    OptionTopModel: [
      { name: "TOP", value: "TOP" },
      { name: "FDD", value: "FDD" },
    ],
    selectSource: null,
    OptionSource: [
      { name: "SKU", value: "SKU" },
      { name: "SKU Category", value: "SKUCa" },
    ],

    status: true,
    notes: "",
    attachment: "",
    createBy: "",
    approvedBy: "",
    dateCreate: "",
    code: "",
    fileAttachment: [],
    IDAttachment: [],
    tommorow: moment().format("YYYY-MM-DD"),
    IDFestives: null,
    searchIC: "",
  }),
  methods: {
    handleBack() {
      this.$router.push({
        name: "product-festives",
      });
    },
    itemCustomLabel(option) {
      return `${option.code} - ${option.name}`;
    },
    itemCustomLabels(option) {
      return `${option.sku_code} - ${option.item_name}`;
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    containsKey(obj, key) {
      return Object.keys(obj).includes(key);
    },
    handleSelectSource(val) {
      if (val.value == "SKU") {
        this.itemCategoryLines = [
          {
            selected_items: null,
          },
        ];
      } else {
        this.itemLines = [
          {
            selected_items: null,
            option_item_unit: [],
            selected_item_unit: null,
            qty: 0,
          },
        ];
      }
    },
    getOptionItemAvailable(index) {
      this.optionItem = [];
      // this.isActive = page;
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/productFestives/table", {
          params: {
            supplier_id: this.supplierID,
            length: 100,
            page: this.page,
            search: this.search,
            order: this.order,
            sort: this.sort,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            // modify data response
            const modifiedData = resp.data.records.map((item) => {
              return {
                ...item,
                isAll: false,
                hu_item_response: [
                  ...item.hu_item_response,
                  {
                    item_unit_id: 0,
                    unit_id: 0,
                    unit_name: "ALL",
                  },
                ],
              };
            });
            this.optionItem = modifiedData;
            //add default line with value ALL in index 0
            this.optionItem.unshift({
              item_id: 0,
              sku_code: "ALL",
              item_name: "ALL",
              isAll: true,
              hu_item_response: [
                {
                  item_unit_id: 0,
                  unit_id: 0,
                  unit_name: "ALL",
                },
              ],
            });
            // console.log("optionItem", this.optionItem);
            console.log("optionItem", this.optionItem);
            console.log("itemLines", this.itemLines);
            this.optionItem = this.optionItem.filter((el) => {
              let cek = this.itemLines.filter((el2) => {
                if (el2.selected_items) {
                  if (el2.selected_items.item_id == el.item_id) {
                    return true;
                  }
                }
                return false;
              });
              if (cek.length > 0) {
                return false;
              }
              return true;
            });
            this.itemLines = this.itemLines.map((itemLine) => {
              return {
                ...itemLine,
                selected_item_unit: {
                  item_unit_id: 0,
                  unit_id: 0,
                  unit_name: "ALL",
                },
              };
            });
            this.$vs.loading.close();
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Item not found with that supplier ",
              color: "warning",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    addRow(index) {
      let cek = this.itemLines.some((el) => {
        if (el.selected_items.item_id == 0) {
          return true;
        }
        return false;
      });
      if (cek) {
        this.$vs.notify({
          title: "Error",
          text: "You select ALL item, you can't add new row",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return true;
      }
      if (this.selectedSupplier.length == 0) {
        this.$vs.notify({
          title: "Error",
          text: "Please select Supplier first",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return true;
      } else {
        if (!this.itemLines[index].selected_items) {
          this.$vs.notify({
            title: "Error",
            text: "Please select Item Line first",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-check",
          });
          return true;
        } else {
          this.itemLines.push({
            option_items: [],
            selected_items: null,
            option_item_unit: [],
          });

          this.getOptionItemAvailable(this.itemLines.length);
        }
      }
    },
    removeRow(index) {
      this.itemLines.splice(index, 1);
    },
    onChangeItems(val, index) {
      let cek = this.itemLines.filter((el, i) => {
        if (el.selected_items) {
          if (el.selected_items.id == val.id && i != index) {
            return true;
          }
        }
        return false;
      });

      if (cek.length > 0) {
        this.itemLines[index].selected_items = null;
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Sales already add",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return false;
      }
      // console.log("val", val);

      this.itemLines[index].selected_items = val;
    },
    onSearchItems(search) {
      console.log(">>>>>>", this.selectedSupplier);
      if (this.selectedSupplier.length == 0) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Please select Supplier first",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return true;
      } else {
        this.search = search;
        this.page = 1;
        this.getOptionItemAvailable();
      }
    },
    SubmitForm() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.handleSubmit();
        } else {
          this.$vs.notify({
            title: "Error",
            text: "Please select all required field first",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-check",
          });
          return;
        }
      });
    },
    handleSubmit() {
      let TerritoryIDs = [];
      this.selectedTerritory.forEach((element) => {
        TerritoryIDs.push(element.ID);
      });

      let Customers = [];
      this.selectedCustomer.forEach((element) => {
        Customers.push({
          id: element.ID,
          name: element.name,
          code: element.code,
        });
      });
      let DistributionChannelIDs = [];
      this.selectedDistributionChannel.forEach((element) => {
        DistributionChannelIDs.push(element.ID);
      });
      let CustomerGr1IDs = [];
      this.selectedCustomerGroup1.forEach((element) => {
        CustomerGr1IDs.push(element.ID);
      });
      let CustomerGr2IDs = [];
      this.selectedCustomerGroup2.forEach((element) => {
        CustomerGr2IDs.push(element.ID);
      });
      let CustomerGr3IDs = [];
      this.selectedCustomerGroup3.forEach((element) => {
        CustomerGr3IDs.push(element.ID);
      });
      let SalesOrderType = [];
      this.selectedSoType.forEach((element) => {
        SalesOrderType.push({
          id: element.ID,
          name: element.Name,
          code: element.Code,
        });
      });
      let SupplierIDs = [];
      this.selectedSupplier.forEach((element) => {
        SupplierIDs.push(element.ID);
      });
      //check each itemLines if there isDefault true then set qty to 0 and selected_item_unit.item_unit_id to 0
      this.itemLines.forEach((el) => {
        if (!el.isDefault) {
          el.qty = 0;
          if (el.selected_item_unit) {
            el.selected_item_unit.item_unit_id = 0;
          }
        }
      });

      this.$vs.loading();
      this.$http
        .post("/api/v1/master/productFestives/store", {
          description: this.description,
          sales_order_type: SalesOrderType,
          top_model: this.selectedTopModel.value,
          date: this.date,
          supplier_id: SupplierIDs,
          valid_from: this.validFrom,
          valid_to: this.validTo,
          payment_term_id: this.selectedTOP ? this.selectedTOP.ID : null,
          territory_id: TerritoryIDs,
          customer_category_id: DistributionChannelIDs,
          customer_group_id_1: CustomerGr1IDs,
          customer_group_id_2: CustomerGr2IDs,
          customer_group_id_3: CustomerGr3IDs,
          customer: Customers,
          note: this.notes,
          source: this.selectSource.value == "SKU" ? 1 : 2,
          item_category:
            this.itemCategoryLines.length > 0
              ? this.itemCategoryLines[0].selected_items != null
                ? this.itemCategoryLines.map((el) => {
                    return {
                      item_category_id: el.selected_items.ID,
                      name: el.selected_items.name,
                      code: el.selected_items.code,
                    };
                  })
                : []
              : [],
          item_sku: this.itemLines[0].selected_items
            ? this.itemLines.map((el) => {
                return {
                  item_unit_id: el.selected_item_unit.item_unit_id,
                  unit_id: el.selected_item_unit.unit_id,
                  qty: Number(el.qty),
                  name: el.selected_items.item_name,
                  code: el.selected_items.sku_code,
                };
              })
            : [],
          status: 0,
          is_active: this.status,
          attachment: this.IDAttachment,
        })
        .then((resp) => {
          // console.log(resp);
          if (resp.code == 200) {
            this.$vs.notify({
              title: "Success",
              text: "TOP has been added",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
            this.IDFestives = resp.data.productFestiveID;
            this.handleSubmitAttachment();
            this.handleBack();
          } else {
            this.attachment = [];
            this.$vs.notify({
              title: "Error",
              text: resp.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
            this.$vs.loading.close();
          }

          this.$vs.loading.close();
        });
    },
    getPaymenTemp() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/productFestives/paymenTerm", {
          params: {
            length: 9999,
            order: "asc",
            sort: "name",
            search: this.searchTop,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              // this.optionTerritory = [{ code: "", name: "ALL", ID: 0 }];
              // for (let index = 0; index < resp.data.records.length; index++) {
              //   this.optionTerritory.push(resp.data.records[index]);
              // }
              this.optionTOP = resp.data.records;
              if (this.optionTOP.length > 0) {
                // this.optionTOP = this.optionTOP[0];
              } else {
                this.optionTOP = [];
                this.selectedTOP = {};
              }
            } else {
              this.optionTOP = [];
              this.selectedTOP = {};
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getTerritory() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/productFestives/territories", {
          params: {
            length: 999,
            order: "name",
            sort: "asc",
            search: this.searchT,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionTerritory = [{ code: "", name: "ALL", ID: 0 }];
              for (let index = 0; index < resp.data.records.length; index++) {
                this.optionTerritory.push(resp.data.records[index]);
              }
              // this.optionTerritory = resp.data.records;
              if (this.optionTerritory.length > 0) {
                // this.selectedTerritory = { code: "", name: "", ID: null };
              } else {
                this.optionTerritory = [];
                this.selectedTerritory = {};
              }
            } else {
              this.optionTerritory = [];
              this.selectedTerritory = {};
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getCustomerCategory() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/productFestives/customer-category", {
          params: {
            length: 999,
            sort: "name",
            order: "asc",
            search: this.searchCC,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionDistributionChannel = [
                { name: "ALL", classification: "", ID: 0 },
              ];
              for (let index = 0; index < resp.data.records.length; index++) {
                this.optionDistributionChannel.push(resp.data.records[index]);
              }
              // this.optionDistributionChannel = resp.data.records;
              if (this.optionDistributionChannel.length > 0) {
                // this.selectedDistributionChannel =
                //   this.optionDistributionChannel[0];
              } else {
                this.optionDistributionChannel = [];
                this.selectedDistributionChannel = {};
              }
            } else {
              this.optionDistributionChannel = [];
              this.selectedDistributionChannel = {};
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getCustomerGroup3() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/productFestives/customer-group", {
          params: {
            length: 9999,
            order: "asc",
            sort: "name",
            search: this.searchCG3,
            level: 3,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionCustomerGroup3 = [{ code: "", name: "ALL", ID: 0 }];
              for (let index = 0; index < resp.data.records.length; index++) {
                this.optionCustomerGroup3.push(resp.data.records[index]);
              }
              // this.optionCustomerGroup3 = resp.data.records;
              if (this.optionCustomerGroup3.length > 0) {
                // this.selectedCustomerGroup3 = this.optionCustomerGroup3[0];
              } else {
                this.optionCustomerGroup3 = [];
                this.selectedCustomerGroup3 = {};
              }
            } else {
              this.optionCustomerGroup3 = [];
              this.selectedCustomerGroup3 = {};
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getCustomerGroup2() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/productFestives/customer-group", {
          params: {
            length: 9999,
            order: "asc",
            sort: "name",
            search: this.searchCG2,

            level: 2,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionCustomerGroup2 = [{ code: "", name: "ALL", ID: 0 }];
              for (let index = 0; index < resp.data.records.length; index++) {
                this.optionCustomerGroup2.push(resp.data.records[index]);
              }
              // this.optionCustomerGroup2 = resp.data.records;
              if (this.optionCustomerGroup2.length > 0) {
                // this.selectedCustomerGroup2 = this.optionCustomerGroup2[0];
              } else {
                this.optionCustomerGroup2 = [];
                this.selectedCustomerGroup2 = {};
              }
            } else {
              this.optionCustomerGroup2 = [];
              this.selectedCustomerGroup2 = {};
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getCustomerGroup1() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/productFestives/customer-group", {
          params: {
            length: 9999,
            order: "asc",
            sort: "name",
            search: this.searchCG1,
            level: 1,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionCustomerGroup1 = [{ code: "", name: "ALL", ID: 0 }];
              for (let index = 0; index < resp.data.records.length; index++) {
                this.optionCustomerGroup1.push(resp.data.records[index]);
              }
              // this.optionCustomerGroup1 = resp.data.records;
              if (this.optionCustomerGroup1.length > 0) {
                // this.selectedCustomerGroup1 = this.optionCustomerGroup1[0];
              } else {
                this.optionCustomerGroup1 = [];
                this.selectedCustomerGroup1 = {};
              }
            } else {
              this.optionCustomerGroup1 = [];
              this.selectedCustomerGroup1 = {};
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getCustomer() {
      let TerritoryIDs = [];
      if (this.selectedTerritory.length != null) {
        this.selectedTerritory.forEach((element) => {
          if (element.ID != 0) {
            TerritoryIDs.push(element.ID);
          }
        });
      }
      let DistributionChannelIDs = [];
      if (this.selectedDistributionChannel.length != null) {
        this.selectedDistributionChannel.forEach((element) => {
          if (element.ID != 0) {
            DistributionChannelIDs.push(element.ID);
          }
        });
      }
      let CustomerGr1IDs = [];
      if (this.selectedCustomerGroup1.length != null) {
        this.selectedCustomerGroup1.forEach((element) => {
          if (element.ID != 0) {
            CustomerGr1IDs.push(element.ID);
          }
        });
      }

      let CustomerGr2IDs = [];
      if (this.selectedCustomerGroup2.length != null) {
        this.selectedCustomerGroup2.forEach((element) => {
          if (element.ID != 0) {
            CustomerGr2IDs.push(element.ID);
          }
        });
      }

      let CustomerGr3IDs = [];
      if (this.selectedCustomerGroup3.length != null) {
        this.selectedCustomerGroup3.forEach((element) => {
          if (element.ID != 0) {
            CustomerGr3IDs.push(element.ID);
          }
        });
      }

      let params = {
        length: 50,
        search: this.searchCust,
        territory_id: TerritoryIDs,
        customer_category_id: DistributionChannelIDs,
        customer_group_id_1: CustomerGr1IDs,
        customer_group_id_2: CustomerGr2IDs,
        customer_group_id_3: CustomerGr3IDs,
      };

      this.$vs.loading();

      this.$http
        .get("/api/v1/master/productFestives/customer", {
          params: params,
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionCustomer = [{ code: "", name: "ALL", ID: 0 }];
              for (let index = 0; index < resp.data.records.length; index++) {
                this.optionCustomer.push(resp.data.records[index]);
              }
              // this.optionCustomer = resp.data.records;
              if (this.optionCustomer.length > 0) {
                // this.selectedCustomer = this.optionCustomer[0];
              } else {
                this.optionCustomer = [];
                this.selectedCustomer = {};
              }
            } else {
              this.optionCustomer = [];
              this.selectedCustomer = {};
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getSoType() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/sales-order-type", {
          params: {
            length: 9999,
            search: this.searchCG1,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionSoType = [{ Code: "ALL", Name: "", ID: 0 }];
              for (let index = 0; index < resp.data.records.length; index++) {
                this.optionSoType.push(resp.data.records[index]);
              }

              if (this.optionSoType.length > 0) {
                // this.selectedSoType = this.optionSoType[0];
              } else {
                this.optionSoType = [];
                this.selectedSoType = {};
              }
            } else {
              this.optionSoType = [];
              this.selectedSoType = {};
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getOptionCustomer(query) {
      if (query.length <= 0) {
        return;
      }
      this.$vs.loading();

      let TerritoryIDs = [];
      if (this.selectedTerritory.length != null) {
        this.selectedTerritory.forEach((element) => {
          if (element.ID != 0) {
            TerritoryIDs.push(element.ID);
          }
        });
      }
      let DistributionChannelIDs = [];
      if (this.selectedDistributionChannel.length != null) {
        this.selectedDistributionChannel.forEach((element) => {
          if (element.ID != 0) {
            DistributionChannelIDs.push(element.ID);
          }
        });
      }
      let CustomerGr1IDs = [];
      if (this.selectedCustomerGroup1.length != null) {
        this.selectedCustomerGroup1.forEach((element) => {
          if (element.ID != 0) {
            CustomerGr1IDs.push(element.ID);
          }
        });
      }

      let CustomerGr2IDs = [];
      if (this.selectedCustomerGroup2.length != null) {
        this.selectedCustomerGroup2.forEach((element) => {
          if (element.ID != 0) {
            CustomerGr2IDs.push(element.ID);
          }
        });
      }

      let CustomerGr3IDs = [];
      if (this.selectedCustomerGroup3.length != null) {
        this.selectedCustomerGroup3.forEach((element) => {
          if (element.ID != 0) {
            CustomerGr3IDs.push(element.ID);
          }
        });
      }

      let params = {
        length: 50,
        search: query,
        territory_id: TerritoryIDs,
        customer_category_id: DistributionChannelIDs,
        customer_group_id_1: CustomerGr1IDs,
        customer_group_id_2: CustomerGr2IDs,
        customer_group_id_3: CustomerGr3IDs,
      };

      this.$http
        .get("/api/v1/master/productFestives/customer", {
          params: params,
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.optionCustomer = [{ code: "", name: "ALL", ID: 0 }];
            for (let index = 0; index < resp.data.records.length; index++) {
              this.optionCustomer.push(resp.data.records[index]);
            }
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Customer option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    getSupplier() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/productFestives/supplier", {
          params: {
            length: 10,
            search: this.searchSup,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionSupplier = resp.data.records;

              if (this.optionSupplier.length > 0) {
                this.optionSupplier = [
                  {
                    Code: "ALL",
                    Name: "",
                    ID: 0,
                  },
                  ...this.optionSupplier,
                ];
                //if (this.selectedSupplier.length == 0) {
                //this.selectedSupplier = this.optionSupplier[0];
                //}
              } else {
                this.optionSupplier = [];
                this.selectedSupplier = [];
              }
            } else {
              this.optionSupplier = [];
              this.selectedSupplier = [];
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    SupplierInput() {
      console.log("SupplierInput", this.selectedSupplier);
      this.itemLines = [
        {
          option_items: [],
          selected_items: null,
          option_item_unit: [],
          selected_item_unit: null,
          qty: [],
        },
      ];
    },
    customLableTerritory({ code, name }) {
      return `${code} ${name}`;
    },
    customLableTOP({ name }) {
      return `${name}`;
    },
    customLableDistributionChannel({ name, classification }) {
      return `${name} ${classification}`;
    },
    customLableCustomer({ code, name }) {
      return `${code} ${name}`;
    },
    customLableCustomerGroup1({ code, name }) {
      return `${code} ${name}`;
    },
    customLableSoType({ Code }) {
      return `${Code}`;
    },
    customLableSupplier({ Code, Name }) {
      return `${Code}-${Name}`;
    },
    clearDay() {
      this.selectedTOP = {};
    },
    clearDate() {
      this.date = "";
    },
    handlerSearchChange(searching) {
      if (
        this.selectedDistributionChannel.length == 0 &&
        this.selectedCustomerGroup1.length == 0 &&
        this.selectedCustomerGroup2.length == 0 &&
        this.selectedCustomerGroup3.length == 0
      ) {
        this.$vs.notify({
          title: "Error",
          text: "Please select Customer Category first",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return true;
      } else {
        this.optionCustomer = [];
        this.searchCust = searching;
        this.getCustomer();
      }
    },
    handlerSearchChangeCG3(searching) {
      this.optionCustomerGroup3 = [];
      this.searchCG3 = searching;
      this.getCustomerGroup3();
    },
    handlerSearchChangeCG2(searching) {
      this.optionCustomerGroup2 = [];
      this.searchCG2 = searching;

      this.getCustomerGroup2();
    },

    handlerSearchChangeCG1(searching) {
      this.optionCustomerGroup1 = [];
      this.searchCG1 = searching;

      this.getCustomerGroup1();
    },
    handlerSearchChangeCC(searching) {
      this.optionDistributionChannel = [];
      this.searchCC = searching;
      this.getCustomerCategory();
    },
    handlerSearchChangeT(searching) {
      this.optionTerritory = [];
      this.searchT = searching;
      this.getTerritory();
    },
    handlerSearchChangeTOP(searching) {
      this.optionTOP = [];
      this.searchTop = searching;
      this.getPaymenTemp();
    },
    handleSearchSupplier(searching) {
      this.optionSupplier = [];
      this.searchSup = searching;
      this.getSupplier();
    },
    // handleFindCustomer() {
    //   if (
    //     this.selectedDistributionChannel.length == 0 &&
    //     this.selectedCustomerGroup1.length == 0 &&
    //     this.selectedCustomerGroup2.length == 0 &&
    //     this.selectedCustomerGroup3.length == 0
    //   ) {
    //     this.$vs.notify({
    //       title: "Error",
    //       text: "Please select Customer Group first",
    //       color: "danger",
    //       position: "top-right",
    //       iconPack: "feather",
    //       icon: "icon-check",
    //     });
    //     return true;
    //   } else {
    //     this.getCustomer();
    //   }
    // },
    mappingAttachment() {
      this.adjustment.Attachment.map((v) => {
        this.fileAttachment.push({
          ListID: v.ListID,
          NameFile: v.NameFile,
          PathFile: v.PathFile,
        });
      });
    },
    handleAttachment() {
      if (this.$refs.file.files.length > 0) {
        this.file = this.$refs.file.files;
        for (let i = 0; i < this.file.length; i++) {
          if (
            this.file[i].type != "application/pdf" &&
            this.file[i].type != "image/jpeg" &&
            this.file[i].type != "image/jpg" &&
            this.file[i].type != "image/png" &&
            this.file[i].type != "application/msword" &&
            this.file[i].type !=
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document" &&
            this.file[i].type != "application/vnd.ms-excel" &&
            this.file[i].type !=
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          ) {
            this.$vs.notify({
              title: "Error",
              text: "File type not allowed",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            return false;
          }
        }

        // this.fileAttachment = [];
        for (let i = 0; i < this.$refs.file.files.length; i++) {
          console.log(this.file[i]);
          // this.uploadData(i);
          this.fileAttachment.push({
            NameFile: this.file[i].name,
            File: this.file[i],
          });
        }
        document.querySelector("#fileInput").value = "";
      } else {
        this.$vs.notify({
          title: "Error",
          text: "Please select file to import.",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
      }
    },
    handleShowAttachment(file) {
      let objectURL;
      // console.log(file)
      objectURL = URL.createObjectURL(file.File);

      // link.download = file.name; // this name is used when the user downloads the file
      var href = objectURL;
      window.open(href, "_blank").focus();
    },
    handleDeleteAttachment(index) {
      // const deleteAttachment =this.fileAttachment[index]
      this.fileAttachment = this.fileAttachment.filter((r, i) => {
        return i != index;
      });
    },
    handleSubmitAttachment() {
      console.log(this.fileAttachment);

      this.$vs.loading();
      if (this.fileAttachment.length > 0) {
        const formData = new FormData();
        for (let index = 0; index < this.fileAttachment.length; index++) {
          formData.append("attachments[]", this.fileAttachment[index].File);
        }
        formData.append("product_festive_id", this.IDFestives);

        this.$http
          .post("/api/v1/master/productFestives/attachment", formData)
          .then((resp) => {
            if (resp.status == "success") {
              for (
                let index = 0;
                index < resp.data.attachment_id.length;
                index++
              ) {
                this.IDAttachment.push(resp.data.attachment_id[index]);
              }
            } else {
              this.$vs.notify({
                color: "danger",
                title: "Failed",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
              this.$vs.loading.close();
            }
            this.$vs.loading.close();
          });
      } else {
        this.$vs.loading.close();
      }
    },
    getOptionItemCategory() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/productFestives/item-category", {
          params: {
            length: 100,
            order: "asc",
            sort: "id",
            search: this.searchIC,
            supplier_id: this.supplierID,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionItemCategory = resp.data.records;
              this.optionItemCategory.unshift({
                code: "ALL",
                name: "ALL",
                ID: 0,
              });
            } else {
              this.optionItemCategory = [];
              this.selectedCategory = {};
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    addRowItemCategory(index) {
      let cek = this.itemCategoryLines.some((el) => {
        if (el.selected_items.ID == 0) {
          return true;
        }
        return false;
      });
      if (cek) {
        this.$vs.notify({
          title: "Error",
          text: "You select ALL item, you can't add new row",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return true;
      }

      if (!this.itemCategoryLines[index].selected_items) {
        this.$vs.notify({
          title: "Error",
          text: "Please select Item Line first",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return true;
      } else {
        this.itemCategoryLines.push({
          option_items: [],
          selected_items: null,
          option_item_unit: [],
        });

        this.getOptionItemCategory(this.itemCategoryLines.length);
      }
    },
    removeRowItemCategory(index) {
      this.itemCategoryLines.splice(index, 1);
    },
    onSearchItemCategory(search) {
      this.searchIC = search;
      this.page = 1;
      this.getOptionItemCategory();
    },
  },
};
</script>
